/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    // document.getElementById("mySidenav").style.width = "250px";
    document.getElementById('page-wrapper').classList.add('mobile-menu-active');
    // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    // document.getElementById("mySidenav").style.width = "0";
    document.getElementById("page-wrapper").classList.remove('mobile-menu-active');
    // document.body.style.backgroundColor = "white";
}

function goBack() {
    $(".sidenav-panel").removeClass("sidenav-panel_open");
}

$(document).ready(function () {

    var $menu = '<div class="sidenav" id="mySidenav">\n' +
        '          <div class="sidenav-header">Menu\n' +
        '            <div class="sidenav-control"><a class="closebtn" href="javascript:void(0)" onclick="closeNav()"><span>×</span></a></div>\n' +
        '          </div>\n' +
        '          <div class="sidenav-panels">\n' +
        '            <div class="sidenav-panel" id="panel-menu">\n' +
        '               <ul><li><a href="/">Home</a></li>' + $( "#nav-main" ).html() + '</ul>\n' +
        '               <ul>' + $( "#nav-second" ).html() + '</ul>\n' +
        '            </div>\n' +
        '          </div>\n' +
        '        </div>';

    $($menu).appendTo( "#page-wrapper" );

    $("#panel-menu ul li.has-subitems").each(function(index,value){

        var subItems = $(value).find(".submenu").html();

        var panel = '<div id="panel-'+ index +'" class="sidenav-panel sidenav-panel-sub"><a href="javascript:void(0)" onclick="goBack()" class="backbtn"><span class="custom-arrow"></span> Terug</a><ul>'+ subItems +'</ul></div>';
        $(".sidenav-panels").append(panel);

        $(this).find('.dropdown-toggle').click(function(e){
            $("#panel-"+index).addClass("sidenav-panel_open");
            $(value).parents("#panel-menu").addClass("sidenav-panel_open");
        });
    });
});